

.gutter {
  background-color: hsl(0, 0%, 100%);
  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter:hover {
  background-color: #1f3266;
  cursor:grab;
  background-repeat: no-repeat;
  background-position: 50%;
}